exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intervisiebegeleiding-js": () => import("./../../../src/pages/intervisiebegeleiding.js" /* webpackChunkName: "component---src-pages-intervisiebegeleiding-js" */),
  "component---src-pages-kerkenwerk-js": () => import("./../../../src/pages/kerkenwerk.js" /* webpackChunkName: "component---src-pages-kerkenwerk-js" */),
  "component---src-pages-over-mij-js": () => import("./../../../src/pages/over-mij.js" /* webpackChunkName: "component---src-pages-over-mij-js" */),
  "component---src-pages-supervisie-en-coaching-js": () => import("./../../../src/pages/supervisie-en-coaching.js" /* webpackChunkName: "component---src-pages-supervisie-en-coaching-js" */),
  "component---src-pages-teamcoaching-js": () => import("./../../../src/pages/teamcoaching.js" /* webpackChunkName: "component---src-pages-teamcoaching-js" */)
}

